import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { footer, footerContent, staffMember, staffRow, fpLogo, madeBy } from './footer.module.css'

const Footer = () => {
    return (
        <footer class={footer}>
            <div class={footerContent}>
                <div class={madeBy}>
                    <h2>Made by</h2>
                    <StaticImage
                        src="../images/fp-logo.png"
                        className={fpLogo}
                        imgStyle={{ objectFit: 'contain' }}
                    />
                </div>

                <div class={staffRow}>
                    <div class={staffMember}>
                        <h1>Thomas Lock</h1>
                        <h2>Developer</h2>
                        <p>Twitch/Pebsie</p>
                    </div>
                    <div class={staffMember}>
                        <h1>Matthew Strelitz</h1>
                        <h2>Developer</h2>
                        <p>Twitch/Qwonko</p>
                    </div>
                    <div class={staffMember}>
                        <h1>Isis Mawdsley-Diaz</h1>
                        <h2>Designer</h2>
                        <p>Twitch/Jakstalock</p>
                    </div>
                </div>

            </div>
        </footer>
    )
}

export default Footer