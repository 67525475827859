import * as React from 'react'
import { layout, main } from './layout.module.css'
import Footer from '../components/footer.js'

const Layout = ({ children}) => {
    return (
        <div className={layout}>
          
                <main className={main}>{children}</main>
                <Footer />
            
      </div>


    )
}

export default Layout
