import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { header, headerBg, navList, navItem, navItemLink } from './header.module.css'

const Header = ({image}) => {
    return (
        <header className={header}>
            <div className={headerBg}>
            </div>
            <ul className={navList}>
                <li className={navItem}><Link className={navItemLink} to="/">HOME</Link></li>
                <li className={navItem}><Link className={navItemLink} to="/links" >SOCIAL LINKS</Link></li>
            </ul>
            {image}
        </header>
    )
}

export default Header