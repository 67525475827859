import * as React from "react"
import "./style.css"
import Layout from '../components/layout.js'
import Header from '../components/header.js'
import { StaticImage } from "gatsby-plugin-image"
import { socialsRow, socialImage, youtubeVideo, mediaRow, tweet} from '../components/links.module.css'
import SocialButton from "../components/social-button.js"
import { headerImage } from '../components/header.module.css'
import chipImg from '../images/header-emote-love.png'

// markup
const IndexPage = () => {
    return (
        <Layout>
            <Header
                image={<img
                    src={chipImg}
                    className={headerImage}
                    alt="Chip's head with hearts"
                />}
            />
            <section>
                <div className={socialsRow}>
                    <SocialButton
                        color={{ backgroundColor: '#9147FF' }}
                        title="Chipstarr878"
                        url="https://twitch.tv/chipstarr878"
                        image={<StaticImage
                            src="../images/social-icons/twitch.png"
                            alt="Twitch Logo"
                            className={socialImage}
                        />}
                    />
                    <SocialButton
                        color={{ backgroundColor: '#7673FF' }}
                        title="Chipstarr878"
                        url="https://twitch.tv/chipstarr878"
                        image={<StaticImage
                            src="../images/social-icons/discord.png"
                            alt="Discord's Logo"
                            className={socialImage}
                        />}
                    />
                    <SocialButton
                        color={{ backgroundColor: '#1DA1F3' }}
                        title="Chipstarr878"
                        url="https://twitter.com/chipstarr878"
                        image={<StaticImage
                            src="../images/social-icons/twitter.png"
                            alt="Twitter Logo"
                            className={socialImage}
                        />}
                    />
                    <SocialButton
                        color={{ backgroundColor: '#DE2925' }}
                        title="Chipstarr878"
                        url="https://www.youtube.com/channel/UCLnAaxrlbfLQFLZBwLYUu0Q"
                        image={<StaticImage
                            src="../images/social-icons/youtube.png"
                            alt="YouTube Logo"
                            className={socialImage}
                        />}
                    />
                    <SocialButton
                        color={{ backgroundColor: '#000000' }}
                        title="Chipstarr878"
                        url="https://tiktok.com/chipstarr878"
                        image={<StaticImage
                            src="../images/social-icons/tiktok.png"
                            alt="TikTok Logo"
                            className={socialImage}
                        />}
                    />
                </div>
                <div className={mediaRow}>
                <blockquote class="twitter-tweet"><p lang="en" dir="ltr">2022 Goals/ plans:<br />- Twitch Partner <br />- 5k on Twitch<br />- 500+ on YouTube<br />- 500 on Twitter<br />- Uncapped Subathon :O<br />- 1k discord<br />- Go to TwitchCon</p>&mdash; Chip (@Chipstarr878) <a href="https://twitter.com/Chipstarr878/status/1477096213438779393?ref_src=twsrc%5Etfw">January 1, 2022</a></blockquote>
                     <div className={youtubeVideo}>
                        <iframe width="560" height="392" src="https://www.youtube.com/embed/FcZeWkfwce4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default IndexPage
